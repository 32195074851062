const p = {
  get: u("GET"),
  post: u("POST"),
  put: u("PUT"),
  delete: u("DELETE")
};
function u(o) {
  let n = null;
  return async (i, e, r, t, h) => {
    const c = {
      method: o,
      headers: {},
      credentials: "include"
    };
    if (c.headers["Content-Type"] = "application/json", r != null && r.forEach((l) => {
      c.headers[l.key] = l.value;
    }), n = new URL(i), t != null && t.length > 0) {
      let l = t.map((f) => `${f.key}=${f.value}`).join("&");
      n.search = l;
    }
    return e && (c.body = JSON.stringify(e)), await d(n, c, h);
  };
}
const s = [];
async function d(o, n, i) {
  if (i) {
    const e = new Promise((r, t) => {
      s.push({ url: o, options: n, resolve: r, reject: t });
    });
    return s.length === 1 && await a(), e;
  } else
    return fetch(o, n).then((e) => e.json()).then((e) => e).catch((e) => {
      throw console.error("API call error:", e), e;
    });
}
async function a() {
  if (s.length === 0)
    return;
  const { url: o, options: n, resolve: i, reject: e } = s[0];
  try {
    const t = await (await fetch(o, n)).json();
    s.shift(), i(t), await a();
  } catch (r) {
    console.error("API call error:", r), s.shift(), e(r), await a();
  }
}
async function y(o, n, i) {
  let e = ".com";
  i == "TH" ? e = ".com" : i == "ID" && (e = ".co.id");
  const r = "https://" + n + e + "/register-client/1.1.0";
  let t = {};
  return t.response = await p.post(r, o, null, null), t.productType = o.ProductType, t.hostName = n, t.country = i, t;
}
export {
  y as registerClient
};